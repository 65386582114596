// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-en-tsx": () => import("./../src/pages/404.en.tsx" /* webpackChunkName: "component---src-pages-404-en-tsx" */),
  "component---src-pages-404-sk-tsx": () => import("./../src/pages/404.sk.tsx" /* webpackChunkName: "component---src-pages-404-sk-tsx" */),
  "component---src-pages-download-en-tsx": () => import("./../src/pages/download.en.tsx" /* webpackChunkName: "component---src-pages-download-en-tsx" */),
  "component---src-pages-download-sk-tsx": () => import("./../src/pages/download.sk.tsx" /* webpackChunkName: "component---src-pages-download-sk-tsx" */),
  "component---src-pages-features-en-tsx": () => import("./../src/pages/features.en.tsx" /* webpackChunkName: "component---src-pages-features-en-tsx" */),
  "component---src-pages-features-sk-tsx": () => import("./../src/pages/features.sk.tsx" /* webpackChunkName: "component---src-pages-features-sk-tsx" */),
  "component---src-pages-help-en-tsx": () => import("./../src/pages/help.en.tsx" /* webpackChunkName: "component---src-pages-help-en-tsx" */),
  "component---src-pages-help-sk-tsx": () => import("./../src/pages/help.sk.tsx" /* webpackChunkName: "component---src-pages-help-sk-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-sk-tsx": () => import("./../src/pages/index.sk.tsx" /* webpackChunkName: "component---src-pages-index-sk-tsx" */),
  "component---src-pages-resources-en-tsx": () => import("./../src/pages/resources.en.tsx" /* webpackChunkName: "component---src-pages-resources-en-tsx" */),
  "component---src-pages-resources-sk-tsx": () => import("./../src/pages/resources.sk.tsx" /* webpackChunkName: "component---src-pages-resources-sk-tsx" */),
  "component---src-templates-help-template-tsx": () => import("./../src/templates/helpTemplate.tsx" /* webpackChunkName: "component---src-templates-help-template-tsx" */),
  "component---src-templates-resources-template-tsx": () => import("./../src/templates/resourcesTemplate.tsx" /* webpackChunkName: "component---src-templates-resources-template-tsx" */)
}

